const {
  REACT_APP_GRAPH_ENDPOINT
} = process.env;

const axios = require('axios');

export async function fetchHistory(userAddress) {
  debugger
  const endpoint = REACT_APP_GRAPH_ENDPOINT;

  const query = `
    query MyQuery {
      claims(
        orderDirection: desc
        where: {user: "${userAddress}"}
      ) {
        amount
        timestamp
      }
      cancelFriendRequests(
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        timestamp
      }
      acceptFriendRequests(
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        timestamp
      }
      clubCreateds(
        orderDirection: desc
        where: {_creator: "${userAddress}"}
      ) {
        _creationFee
        blockTimestamp
      }
      clubJoineds(
        orderDirection: desc
        where: {_joiner: "${userAddress}"}
      ) {
        _joiningFee
        blockTimestamp
      }
      flicsactivateds(
        orderDirection: desc
        where: {_initiator: "${userAddress}"}
      ) {
        _amount
        blockTimestamp
      }
      flicsrefundeds(
        orderDirection: desc
        where: {_initiator: "${userAddress}"}
      ) {
        _amount
        blockTimestamp
      }
      paidSubscriptions(
        orderDirection: desc
        where: {account: "${userAddress}"}
      ) {
        amount
        blockTimestamp
      }
      rejectFriendRequests(
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        blockTimestamp
      }
      sentFriendRequests(
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        blockTimestamp
      }
      viewMatchFees_collection(
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        blockTimestamp
      }
    }
  `;

  try {
    const response = await axios.post(endpoint, {
      query: query,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}