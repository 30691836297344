import React, { useState, useEffect } from 'react';
import TimelineNav from "../../components/timelinenav/timelineNav";
import { fetchHistory } from "../../axios/web3/graphTransactionHistory";
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import transOutIcon from "src/assets/images/transouticon.svg";
import transInIcon from "src/assets/images/transinicon.svg";
import "./transactions.css";

function Transactions({ setSliderPage }) {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      debugger
      try {
        const userAddress = localStorage.getItem("wallet_address");
        const response = await fetchHistory(userAddress);
        const data = response.data;

        const allTransactions = [
          ...mapTransactions(data.claims, 'Claim', 'in'),
          ...mapTransactions(data.sentFriendRequests, 'Add Friend', 'out'),
          ...mapTransactions(data.acceptFriendRequests, 'Accept Friend', 'in'),
          ...mapTransactions(data.rejectFriendRequests, 'Reject Friend', 'out'),
          ...mapTransactions(data.cancelFriendRequests, 'Cancel Friend', 'in'),
          ...mapTransactions(data.flicsactivateds, 'Flics Activate', 'out', '_amount'),
          ...mapTransactions(data.flicsrefundeds, 'Flics Refund', 'in', '_amount'),
          ...mapTransactions(data.clubCreateds, 'Create Club', 'out', '_creationFee'),
          ...mapTransactions(data.clubJoineds, 'Join Club', 'out', '_joiningFee'),
          ...mapTransactions(data.viewMatchFees_collection, 'Viewmatch Fees', 'out'),
          ...mapTransactions(data.paidSubscriptions, 'Subscription', 'out'),
        ];

        allTransactions.sort((a, b) => Number(b.blockTimestamp) - Number(a.blockTimestamp));

        setTransactions(allTransactions);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Failed to load transactions. Please try again later.');
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const mapTransactions = (transactionArray, type, direction, amountKey = 'amount') => {
    return (transactionArray || []).map(t => ({
      type,
      direction,
      amount: t[amountKey],
      blockTimestamp: t.blockTimestamp || t.timestamp,
    }));
  };
  
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
  };

  return (
    <div className='page-page-wrapper flics-page-container'>
      <TimelineNav />
      <div className="inner-pages-container">
        <div className="inner-pages-container-wrap">
          <h1 className="page-title">Transactions</h1>
          <div className="transaction-container">
            {loading ? (
              <p>Loading transactions...</p>
            ) : error ? (
              <p>{error}</p>
            ) : transactions.length === 0 ? (
              <p>No transactions found.</p>
            ) : (
              transactions.map((transaction, index) => (
                <div className="transaction-row" key={index}>
                  <div className="trans-details-wrap">
                    <div className="trans-title"><span>{transaction.type}</span></div>
                    <div className="trans-date">{formatDate(transaction.blockTimestamp)}</div>
                  </div>
                  <div className="trans-bal">
                    <span><img src={dropdTokanIcon} alt="Token" /></span>
                    <span>{Number(transaction.amount) / 1e18}</span>
                    <span>
                      <img 
                        src={transaction.direction === 'in' ? transInIcon : transOutIcon} 
                        alt={transaction.direction === 'in' ? "In" : "Out"} 
                      />
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;